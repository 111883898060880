.drawer-row{
    margin-top: 0px;
}
.datePicker.ant-picker{
    width: 275px;
}

/* .ant-modal-content{
    width: 600px;
} */

.search-button{
    width: 40em;
}
.list-detail-table
.ant-table-thead>tr>th {
background-color:#c4c4c4 ;
}
.list-detail-table
.ant-table-thead>tr>th:hover{
background-color:rgb(243, 243, 243) ;
}
.list-detail-table .ant-table-filter-trigger:hover{
  background-color:#c1cedb ;
}

/* .modal{
    width: 1100px;
} */

.inventory-detail-table {
    height: 400px;
}
.inventory-detail-table .ant-table-container{
    max-height: 400px;
}

.inventory-detail-table .ant-table-title{
    font-size: medium;
    font-weight: bold;
}

.inventory-select > .ant-select-selector{
    max-height: 710px;
    overflow: auto;
}

.inventory-form .search-close-btn{
    margin-left: 100px;
}

/* .inventory-highlight-red-font{
    color: red;
    font-weight: normal !important;
}

.inventory-highlight-red-font.ant-table-row:hover{
    color: red;
    font-weight: normal !important;
} */


.inventory-detail-editable-cell {
    position: relative;
}

.inventory-detail-editable-cell-value-wrap {
    height: 30px;
    padding: 5px 12px;
    cursor: pointer;
}

.inventory-detail-editable-row .inventory-detail-editable-cell-value-wrap {
    border: 1px solid #d9d9d9;
}

.inventory-detail-editable-row:hover .inventory-detail-editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #A9A9A9;
    border-radius: 2px;
}

.inventory-detail-editable-row-block:hover .inventory-detail-editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #A9A9A9;
    border-radius: 2px;
    background-color: gray;
    cursor: not-allowed
}

[data-theme='dark'] .inventory-detail-editable-row:hover .inventory-detail-editable-cell-value-wrap {
    border: 1px solid #434343;
}