.buttonGrp {
    display: inline;
    /* margin-left:800px */

}

.ant-avatar{
  background: #000000;
}
.ant-avatar-lg.ant-avatar-icon {
    font-size: 35px;
}
.ant-tooltip-inner{
    min-width: 190px;
}
.ant-btn:hover{
    color: #ffffff;
    border-color:#6B5CEA;
    background: #6B5CEA;
}
.ant-menu-inline{
    width: 100%;
    height: 100%;
}
.ant-layout-header{

    height: 74px;
    width: 100vw;
    padding: 10px 60px;
    background: #000000
}
.ant-menu.ant-menu-dark{
    background: #000000
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #18181a;
}

.ant-layout-sider{
     background: #ffffff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    color: #000;
     background-color: #E3E1F8;
}
/* .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #2974bb;
} */


.ant-menu-inline .ant-menu-item::after{
 border-right-color: #6B5CEA;
}

.footer{
     background: #6B5CEA;
}

.ant-menu-item-selected a{
    color:#6B5CEA;
}
.ant-menu-item a:hover{
    color:#6B5CEA;
}

/* .ant-menu{
    color:#6B5CEA;
} */

.ant-menu-submenu-title:hover{
    color:#6B5CEA;
}

.ant-layout-footer {
    padding: 14px 30px;
    color: #fff;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    /* min-width: 1920px; */
}

.ant-menu-inline {
    border-right: 3px solid #c4c4c4;
}
.site-layout-background{
    height: calc(100vh - 124px);
    overflow-y: auto;
}
.ant-table-container{
    height: 100%;
}

body {
    min-width: 0; 
}

