@import '~antd/dist/antd.css';

.App {
    /* width: 1920px; */
}

.ant-layout-content site-layout-background{
    position: relative;
}

/* .ant-spin-nested-loading, .ant-spin-container{
    position: absolute !important;
} */
.ant-layout.ant-layout-has-sider{
   
}

/* .ant-table table{
    width: 0 !important;
} */