/* ホームページ画面全体 */
.homeBox {
    height: 83.5vh;
    width: 86.5vw;
	overflow:auto;
    border: 0px;
}

/* 納入管理 */
.delivery{
    width: 23vw;
    position: absolute; 
    left: 6vw;
    top: 2.5vw; 
}

/* 所要計画管理 */
.forecast{
    width: 23vw;
    position: absolute;
    left: 6vw;
}

/* 検収管理 */
.acceptance{
    width: 23vw;
    position: absolute;
    left: 32.5vw;
    top: 2.5vw;
}

/* 見積管理 */
.estimate{
    width: 23vw;
    position: absolute;
    left: 32.5vw;
    top: 12vw;

}

/* 金型管理 */
.modelManagement{
    width: 23vw;
    position: absolute;
    left: 59vw;
    top: 2.5vw;
}

/* ドキュメント管理 */
.docInfo{
    width: 23vw;
    position: absolute;
    left: 59vw;
}

.delivery{
    font-size: 6vw;
}


.homeBox-li{
    border-bottom:1px solid black;
    font-size: 1vw;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 5vw;
}

.homeBox-tb{
    margin-top: -0.1vw;
    margin-bottom: -0.1vw;
}

.homeBox-tr td{
    /* font-size: 0.7vw; */
    width: 15vw;
    margin-top: 0.5vw;
    text-align: center;
}

.estimate-topline{
    border-top: 0px;
}

.homespin {
    position: absolute;
    left: 52vw;
    top: 13vw;
  }

.fontsize{
    font-size: 1.2vw;
    margin-left: -3em;
    margin-right: 0.8vw;
} 

.fontleft{
    font-size: 1.2vw;
    margin-left: -1em;
}