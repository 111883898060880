.add-btn {
    color: rgb(255, 255, 255);
    background-color: #FBC041;
    border-radius: 0.25em;
    margin: 0 0.63em;
}
.download-btn {
    color: rgb(255, 255, 255);
    background-color: #6564E6;
    border-radius: 0.25em;
    margin: 0 0.63em;
}
.add-btn:hover {
    background: #FDD888;
    color: #FFFFFF;
    border-color: #FFFFFF;
}
.download-btn:hover {
    background: #9190FC;
    color: #FFFFFF;
    border-color: #FFFFFF;
}
.site-form-in-drawer-wrapper {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    padding: 0.62em 1em;
    border-top: 0.06em solid #E9E9E9;
    background: #fff;
    text-align: right;
}
.search-search-btn {
    margin-left: 1.88em;
}
.search-button {
    text-align: center;
    width: 31.25em;
}
.formButton {
    text-align: center;
    width: 47em;
}
.submitBtn {
    margin-left: 8em;
}

/* .ant-form-item-label>label{
    font-weight: bold;
    margin-right: 10%;
}
.form .ant-form-item-control-input-content{
    text-decoration-line: underline;
} */
/* .ant-form-item{
    margin-bottom: 15px;
} */

/* .documentlist-table
.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
    padding: 8px 16px;
} */


.deleteBtn{
    padding: 0px 5px;
    height: min(3vh, 22px);

    display: grid;
    align-items: center;
    justify-items: center;
}

#createFormButton {
    width: 948px;
}

/* .customSelect :global .ant-select-selection {
    max-height: 51px;
    overflow: auto;
} 
.ant-select-selection-overflow{
    max-height: 400px; 
} */

.list-expand-table{
    margin-top:10px;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 10px;
}



.list-expand-table
.ant-table-thead>tr>th {
/* background-color:#c4c4c4 ; */
background-color:rgb(232, 233, 238) ;
}
.list-expand-table
.ant-table-thead>tr>th:hover{
/* background-color:rgb(243, 243, 243) ; */
background-color:rgb(131, 131, 131) ;
}
.list-expand-table .ant-table-filter-trigger:hover{
  /* background-color:#c1cedb ; */
  /* background-color:blue ; */
}


  /* highlight */
  .table-expand-tr-highlight{
    /* background-color: #CDE2FB; */
    /* color: #6B5CEA; */
    font-weight:bold;
    background-color:rgb(240, 240, 240);
  }
  .table-expand-tr-highlight.ant-table-row:hover td{
    /* color: #6B5CEA; */
    font-weight:bold;
      background: rgb(241, 243, 245) !important;
  }
  .table-expand-tr-highlight td.ant-table-column-sort{
    /* color: #6B5CEA; */
    font-weight:bold;
    background-color: #fff ;
  }
  .table-expand-tr-highlight a{
    text-decoration: underline;
  }


  .table-expand-tr-highlight-zebracolor{
    /* color: #6B5CEA; */
    font-weight:bold;
    background-color:#eff3f7;
  }
  .table-expand-tr-highlight-zebracolor.ant-table-row:hover td{
    /* color: #6B5CEA; */
    font-weight:bold;
    background-color:rgb(229, 237, 245);
  }
  .table-expand-tr-highlight-zebracolor td.ant-table-column-sort{
    /* color: #6B5CEA; */
    font-weight:bold;
    background-color:#eff3f7;
  }
  .table-expand-tr-highlight-zebracolor a{
    text-decoration: underline;
  }

  /* normal */
  .table-expand-tr-normal{
    background-color: #FFF;
  }
  .table-expand-tr-normal.ant-table-row:hover td{
      background: rgb(245, 249, 253) !important;
  }
  .table-expand-tr-normal td.ant-table-column-sort{
    background-color: #fff ;
  }
  .table-expand-tr-normal a{
    text-decoration: underline;
  }

  /* zebracolor */
  .table-expand-tr-normal-zebracolor{
    background-color:rgba(250, 249, 255, 0.897);
  }
  .table-expand-tr-normal-zebracolor.ant-table-row:hover td{
    background-color:rgb(229, 237, 245);
  }
  .table-expand-tr-normal-zebracolor td.ant-table-column-sort{
    background-color:#eff3f7;
  }
  .table-expand-tr-normal-zebracolor a{
    text-decoration: underline;
  }

  tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover {
    background-color: #fdfdfd;
  }

  /* tr.ant-table-expanded-row > td :hover {
    background-color: #cc3333;
 } */


 .reSize .ant-progress {
   line-height: 0px !important;
 }
 .reSize .ant-upload-list-item-progress {
    position: static !important;
 }

 .reSize .ant-upload-list-item {
    height: auto !important;
 }

 .buyerDocDetailFileList {
  max-width: 100%;
 }
 .buyerDocDetailFileList span {
   max-width: 100%;
 }

 .buyerDocDetailFileList .ant-upload-list {
  max-width: 100%;
 }