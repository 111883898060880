.btn-search:hover{
 background: #fdd888;
}
.list-detail-table{
  /* padding: 10px 10px; */
    /* margin-top: -20px !important; */
}

#estimeation-detail-table{
  height: 100% !important;
  margin-top: -25px !important;
}

.bottom-left-padding {
  margin-left: 20px;
}

.number-input-overwrite {
  width: 100% !important;
}

.picker-overwrite{
  width: 100% !important;
}

.submitBtn {
  margin-left: 8em;
}
.search-button{
  margin-right:20px;
}
.estimate-edite {
  text-align:'left';
  font-weight:'bold';
  margin-bottom:10px
}
body {
  /* overflow-x: hidden; */
}

pre {
  margin-bottom: 0 !important;
}