.form{
    margin: 0;
}

.ant-picker{
    width: 225px;
}
.formItem-high{
    margin-bottom: 5px;
}
.ant-card-body{
    padding:10px;
}

.ant-card-head-title{
    padding: 0px;
}
.formItem-style{
    text-align:left;
    font-weight:bold;
    margin-bottom:10px;
}

.form-select > .ant-select-selector{
    max-height: 60px;
    overflow: auto;
}

.form-textarea{
    width: 500px;
    border: 1px solid #d9d9d9;
}

.upload-buttom{
    transform: translateY(30px);
}

.model-delete-button {
    margin-top: 12px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}