.filter {
    /* width: 18em; */
    padding: 0.5em;
}

.selectFilter {
    width: 40em;
    padding: 0.5em;
}

.filterRow {
    margin-top: 7px;
}


.picker .site-input-split {
    background-color: #fff;
    /* margin-left: '1px'; */
}

.picker .site-input-right {
    border-left-width: 0;
}

.picker .site-input-right:hover,
.picker .site-input-right:focus {
    border-left-width: 1px;
}

.picker .ant-input-rtl.site-input-right {
    border-right-width: 0;
}

.picker .ant-input-rtl.site-input-right:hover,
.picker .ant-input-rtl.site-input-right:focus {
    border-right-width: 1px;
}





.picker .site-input-left {
    z-index: 50;
    border-right-width: 0px;
}

.picker .site-input-left:hover,
.picker .site-input-left:focus {
    z-index: 50;
    border-right-width: 1px;
}

.picker .ant-input-rtl.site-input-left:hover,
.picker .ant-input-rtl.site-input-left:focus {
    z-index: 50;
    border-right-width: 1px;
}
