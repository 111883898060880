body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width:1920px
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ant-layout.ant-layout-has-sider>.ant-layout,
.ant-layout.ant-layout-has-sider>.ant-layout-content{
      background-color: #fff;
}
/* .ant-layout{
  height: 700px;
} */

/* .documentlist-table */

.orderList-table
.ant-table-thead>tr>th {
background-color: #a5bacf ;
}
.orderList-table .ant-table-thead>tr>th:hover{
background-color:#b8c9db ;
}
.orderList-table .ant-table-filter-trigger:hover{
  background-color:#c1cedb ;
}
.orderList-table .ant-table-column-sorter{
  color:#fff;
}
.orderList-table .ant-table-filter-trigger{
  color:#fff;
}
.ant-table-filter-trigger.active{
  color: #1890ff !important;
}


/* .documentlist-table */


.documentlist-table
.ant-table-thead>tr>th {
background-color: #a5bacf ;
}
.documentlist-table .ant-table-thead>tr>th:hover{
background-color:#b8c9db ;
}
.documentlist-table .ant-table-filter-trigger:hover{
  background-color:#c1cedb ;
}
.documentlist-table .ant-table-column-sorter{
  color:#fff;
}
.documentlist-table .ant-table-filter-trigger{
  color:#fff;
}
/* .acceptancelist-table */

.acceptancelist-table
.ant-table-thead>tr>th {
background-color:#a5bacf  ;
}
.acceptancelist-table
.ant-table-thead>tr>th:hover{
background-color:#b8c9db ;
}
.acceptancelist-table .ant-table-filter-trigger:hover{
  background-color:#c1cedb ;
}
.acceptancelist-table .ant-table-column-sorter{
  color:#fff;
}
.acceptancelist-table .ant-table-filter-trigger{
  color:#fff;
}

/* list-table-title-background-color */

/* 表头颜色 */
.list-table
.ant-table-thead>tr>th {
background-color:#c4c4c4 ;
}
/* 表头hover颜色 */
.list-table
.ant-table-thead>tr>th:hover{
background-color:rgb(121, 121, 121) ;
color: white;
}
.list-table .ant-table-filter-trigger:hover{
  background-color:rgb(165, 165, 165) ;
  color: white;
}
.list-table .ant-table-column-sorter{
  color:rgb(255, 255, 255);
}
.list-table .ant-table-filter-trigger{
  color:rgb(255, 255, 255);
}

.ant-table-thead th.ant-table-column-has-sorters:hover{
  background: rgb(121, 121, 121) !important;
}
.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container{
  background: rgb(121, 121, 121) !important;
}



/* list-table-size */

.list-table  .ant-table-scroll-horizontal>.ant-table-container{
  /* height: calc(100vh - 250px); */
  /* width: 50px;
  width: calc(100vw - 50px);
  height: calc(100vh - 60px); */
  /* width: calc(100vw - 205px); */
  overflow: auto;
   /* overflow-x: auto; */


  /* overflow-x:auto ; */
}
.list-table .ant-layout.ant-layout-has-sider{
  /* height: 870px; */
}
.list-table .ant-table-pagination.ant-pagination{
    margin:7px 0 0 15px !important;
    position: fixed;
    bottom: 60px;
}


/* highlight */
.table-tr-highlight{
  /* background-color: #CDE2FB; */
  /* color: #6B5CEA; */
  font-weight:bold;
  background-color:#fff;
}
.table-tr-highlight.ant-table-row:hover td{
  /* color: #6B5CEA; */
  font-weight:bold;
	background: rgb(245, 249, 253) !important;
}
.table-tr-highlight td.ant-table-column-sort{
  /* color: #6B5CEA; */
  font-weight:bold;
  background-color: #fff ;
}
.table-tr-highlight a{
  text-decoration: underline;
}



.table-tr-highlight-zebracolor{
  /* color: #6B5CEA; */
  font-weight:bold;
  background-color:#eff3f7;
}
.table-tr-highlight-zebracolor.ant-table-row:hover td{
  /* color: #6B5CEA; */
  font-weight:bold;
  background-color:rgb(229, 237, 245);
}
.table-tr-highlight-zebracolor td.ant-table-column-sort{
  /* color: #6B5CEA; */
  font-weight:bold;
  background-color:#eff3f7;
}
.table-tr-highlight-zebracolor a{
  text-decoration: underline;
}



/* normal */
.table-tr-normal{
  background-color:#fff;
}
.table-tr-normal.ant-table-row:hover td{
	background: rgb(245, 249, 253) !important;
}
.table-tr-normal td.ant-table-column-sort{
  background-color: #fff ;
}
.table-tr-normal a{
  text-decoration: underline;
}


/* zebracolor */
.table-tr-normal-zebracolor{
  background-color:#eff3f7;
}
.table-tr-normal-zebracolor.ant-table-row:hover td{
  background-color:rgb(229, 237, 245);
}
.table-tr-normal-zebracolor td.ant-table-column-sort{
  background-color:#eff3f7;
}
.table-tr-normal-zebracolor a{
  text-decoration: underline;
}

/* highlight */
.table-tr-highlight-inventory{
  /* background-color: #CDE2FB; */
  /* color: #6B5CEA; */
  /* font-weight:bold; */
  color: red;
  background-color:#fff;
}
.table-tr-highlight-inventory.ant-table-row:hover td{
  /* color: #6B5CEA; */
  /* font-weight:bold; */
  color: red;
	background: rgb(245, 249, 253) !important;
}
.table-tr-highlight-inventory td.ant-table-column-sort{
  /* color: #6B5CEA; */
  /* font-weight:bold; */
  color: red;
  background-color: #fff ;
}
.table-tr-highlight-inventory a{
  text-decoration: underline;
}



.table-tr-highlight-inventory-zebracolor{
  /* color: #6B5CEA; */
  /* font-weight:bold; */
  color: red;
  background-color:#eff4f7;
}
.table-tr-highlight-inventory-zebracolor.ant-table-row:hover td{
  /* color: #6B5CEA; */
  /* font-weight:bold; */
  color: red;
  background-color:rgb(229, 237, 245);
}
.table-tr-highlight-inventory-zebracolor td.ant-table-column-sort{
  /* color: #6B5CEA; */
  /* font-weight:bold; */
  color: red;
  background-color:#eff3f7;
}
.table-tr-highlight-inventory-zebracolor a{
  text-decoration: underline;
}


.reset-btn{
   float: right;
}
.ant-table-title{
  /* width: calc(100vw - 205px); */
}

.select-mutil{
  max-height: 144px;
  overflow-y: auto;
}
.ant-table-container{
    margin-left:5px;
}


