.add-btn {
    color: rgb(255, 255, 255);
    background-color: #fbc041;
    border-radius: 4px;
    margin: 0 10px;

}

.download-btn {
    color: rgb(255, 255, 255);
    background-color: #79dcc6;
    border-radius: 4px;
    margin: 0 10px;


}

.add-btn:hover {
    background: #fdd888;
    color: #ffffff;
    border-color: #ffffff;
}

.download-btn:hover {
    background: #9df7e3;
    color: #ffffff;
    border-color: #ffffff;
}


.site-form-in-drawer-wrapper {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    padding: 10px 16px;
    border-top: 1px solid #e9e9e9;
    background: #fff;
    text-align: right;
}


.search-search-btn {
    margin-left: 30px;
}

.search-button {
    text-align: center;
    width: 500px;
}

.formButton {
    text-align: center;
    width: 750px;
}

.submitBtn {
    margin-left: 30px;
}

.acceptanceListRecordDto-highlight{
    background-color: #f9c66c;
}

.acceptanceListRecordDto-highlight.ant-table-row:hover td{
	background: #f8ce84!important;
}
.acceptanceListRecordDto-highlight td.ant-table-column-sort{
     background-color: #f9c66c;
}
/* .acceptancelist-table .ant-table-container{
  height: 600px;
} */

a{
  color: #6B5CEA;
}
a:hover {
  color: #9c91fa;

}

.acceptance-search-form > .ant-form-item{
    margin-bottom: 20px;
}