.ant-menu-submenu-title {
    height: clamp(30px,4vh,40px) !important;
    line-height: clamp(30px,4vh,40px) !important;
}

.ant-menu-item {
    height: clamp(30px,4vh,40px) !important;
    line-height: clamp(30px,4vh,40px) !important;
}
.ant-menu-item-only-child {
    height: clamp(30px,4vh,40px) !important;
    line-height: clamp(30px,4vh,40px) !important;
}
.ant-menu {
    font-size: clamp(12px,1.5vh,14px) !important;
}