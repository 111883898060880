/* .add-btn {
    color: rgb(255, 255, 255);
    background-color: #fbc041;
    border-radius: 4px;
    margin: 0 10px;

} */

/* .download-btn {
    color: rgb(255, 255, 255);
    background-color: #79dcc6;
    border-radius: 4px;
    margin: 0 10px;


} */

.add-btn:hover {
    background: #fdd888;
    color: #ffffff;
    border-color: #ffffff;
}

/* .download-btn:hover {
    background: #9df7e3;
    color: #ffffff;
    border-color: #ffffff;
} */


.site-form-in-drawer-wrapper {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    padding: 0.62em 1em;
    border-top: 1px solid #e9e9e9;
    background: #fff;
    text-align: right;
}


.search-search-btn {
    margin-left: 1.88em;
}

.search-button {
    text-align: center;
    width: 31.25em;
}

.formButton {
    text-align: center;
    width: 47em;
}

.submitBtn {
    margin-left: 2em;
}

.labelTitle {
    text-align: 'right';
}
.list-detail-table{
    margin-top:10px;
    margin-left: 24px;
    margin-right: 24px;
}

.list-detail-table
.ant-table-thead>tr>th {
background-color:#c4c4c4 ;
}
.list-detail-table
.ant-table-thead>tr>th:hover{
background-color:rgb(243, 243, 243) ;
}
.list-detail-table .ant-table-filter-trigger:hover{
  background-color:#c1cedb ;
}
.divider{
    margin: 0;
}

.payment-note{

    margin: 10px 25px 0 30px;

    padding: 5px 20px 5px 20px;

    font-size: 14px;

    border: solid 2px rgb(122, 122, 122)
}
