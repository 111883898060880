/* .add-btn {
    color: rgb(255, 255, 255);
    background-color: #fbc041;
    border-radius: 4px;
    margin: 0 10px;

} */

/* .download-btn {
    color: rgb(255, 255, 255);
    background-color: #79dcc6;
    border-radius: 4px;
    margin: 0 10px;


} */

.add-btn:hover {
    background: #fdd888;
    color: #ffffff;
    border-color: #ffffff;
}

/* .download-btn:hover {
    background: #9df7e3;
    color: #ffffff;
    border-color: #ffffff;
} */


.site-form-in-drawer-wrapper {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100%;
    padding: 0.62em 1em;
    border-top: 0.06em solid #e9e9e9;
    background: #fff;
    text-align: right;
}


.search-search-btn {
    margin-left: 1.88em;
}

.search-button {
    text-align: center;
    width: 31.25em;
}

.formButton {
    text-align: center;
    width: 47em;
}

.submitBtn {
    margin-left: 2em;
}

.bottom-padding {
    margin-right: 20px;
    margin-left: 20px;
}

.bottom-padding-10 {
    margin-right: 10px;
    margin-left: 20px;
}

.ant-drawer-wrapper-body{
    overflow-x: hidden;
}