.guidBox ul{
    font-size: 1.05em;
    padding: 0;
    margin: 0;
    /* color: green; */
}
.guidBox li{
    list-style: none;
}

.scrollBox{
    /* width: calc(100vw - 200px); */
    height: calc(100vh - 124px);
    overflow-y: auto;
    overflow-x: auto;
}
.guidBox {
    /* width: calc(100vw - 200px); */
    min-width: 800px;
    height: 100%;
    display: flex;
    justify-content: center;
}
.guidItems {
    width: 58%;
    min-width: 800px;
    margin-top: calc(20vh - 93px);
}