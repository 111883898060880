.agreementMainTitle {
    font-size: 28px;
}
.agreementSmallTitle {
    font-size: 1.5em;
}
.agreementText {
    width: calc(100% - 19px);
    font-size: 16px;
}
.agreementTextNoMargin {
    width: calc(100% - 19px);
    font-size: 16px;
    margin-bottom: 0px;
}
.agreementTextLittleMargin {
    width: calc(100% - 19px);
    font-size: 16px;
    margin-bottom: 5px;
}

@supports not (backdrop-filter: blur(10px)) {
    .agreementBlurRouter {
        filter: blur(10px) !important; 
        -webkit-filter: blur(10px) !important;
        -moz-filter: blur(10px) !important;
        -ms-filter: blur(10px) !important;
        -o-filter: blur(10px) !important; 
    }
}
.agreementBlurRouter {
    position: absolute !important; 

    width: 100vw !important; 
    height: calc(100vh - 124px) !important; 
}

@supports (backdrop-filter: blur(10px)) {
    .agreementMaskAndBox {
        backdrop-filter: blur(10px) !important;
    }
}
.agreementMaskAndBox {
    position: absolute !important;

    width: 100vw !important; 
    height: calc(100vh - 124px) !important; 

    background-color: #00000013 !important;  

    display: grid !important; 
    justify-items: center !important; 
    align-items: center !important; 
    align-content: center !important;
}

/* 白色圆角背景盒子 */
.agreementBox {
    
    width: calc(60vw);
    max-width: 1200px;
    min-width: 450px;


    height: calc(70vh);
    max-height: 800px;
    min-height: 200px;

    display: grid;
    justify-items: center;
    align-items: center;
    align-content: space-evenly;
    
    background-color: white;
    border-radius: 12px;

    z-index: 1000;
}

/* 用来装协议文字的盒子 */
.agreementTextBox {
    width: calc(60vw - 100px);
    max-width: 1100px;
    min-width: 350px;
    height: calc(70vh - 200px);
    max-height: 700px;
    min-height: 100px;
    overflow-y: auto;
}
/* 存放多选框和进入按钮的盒子 */
.agreementButtonBox {
    width: calc(60vw - 200px);
    max-width: 1000px;
    min-width: 250px;
}
/* 以下是覆盖用来装协议文字的盒子的滚动条样式 */
/* 滚动条宽度 */
.agreementTextBox::-webkit-scrollbar {
    width: 6px;
}
/* 滚动条轨道 */
.agreementTextBox::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
  /* border-radius: 2px; */
}
/* 滚动条的小滑块 */
.agreementTextBox::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.164);
  border-radius: 10px;
}
.agreementTextBox::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.349);
}