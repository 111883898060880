.ant-table-tbody > tr > td {
  padding: clamp(7px, calc( (1vh * 5) - 30px ), 16px) 16px;

  font-size: min(1.5vh, 14px);
}


/* 以下是覆盖 body 页的滚动条样式 */
/* 滚动条宽度 */
.ant-layout-content::-webkit-scrollbar {
  width: 10px;
}
/* 滚动条轨道 */
.ant-layout-content::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
/* border-radius: 2px; */
}
/* 滚动条的小滑块 */
.ant-layout-content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.164);
  border-radius: 10px;
}
/* 滚动条的小滑块的hover */
.ant-layout-content::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.349);
}